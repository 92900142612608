// !important used here because it needs to win over a specificity of a simple ".parent .child"
// listing as the last override.scss file doesn't override higher specificity selectors
$displays: (
  hidden: (
    base: none !important
  ),
  hidden-sm1: (
    phone-small: none !important
  ),
  hidden-sm2: (
    phone: none !important
  ),
  hidden-sm3: (
    phone-large: none !important
  ),
  hidden-md1: (
    tablet-small: none !important
  ),
  hidden-md2: (
    tablet: none !important
  ),
  hidden-md3: (
    tablet-large: none !important
  ),
  hidden-lg1: (
    desktop-small: none !important
  ),
  hidden-lg2: (
    desktop: none !important
  ),
  hidden-lg3: (
    desktop-large: none !important
  ),
  visible: (
    base: block !important
  ),
  visible-sm1: (
    phone-small: block !important
  ),
  visible-sm2: (
    phone: block !important
  ),
  visible-sm3: (
    phone-large: block !important
  ),
  visible-md1: (
    tablet-small: block !important
  ),
  visible-md2: (
    tablet: block !important
  ),
  visible-md3: (
    tablet-large: block !important
  ),
  visible-lg1: (
    desktop-small: block !important
  ),
  visible-lg2: (
    desktop: block !important
  ),
  visible-lg3: (
    desktop-large: block !important
  )
);
