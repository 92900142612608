// @import '~ui-experience-system.settings';
@import '../settings/styles';

// ---------------------------------
// ---------  overrides  -----------
// ---------------------------------

.clearfix:after {
  content: '';
  display: block;
  clear: both;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.no-margin {
  margin: 0;
}

// xD-TODO: rework these (or do manually?) to have same sizes in same place
// see third bullet point here: https://github.tlcinternal.com/LendingClub/website-content-pages/pull/257
@include responsiveFactory($displays, null, display);
