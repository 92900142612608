@import '~shared_modules/ui-experience-system/settings/styles.scss';
@import '~shared_modules/ui-experience-system/forms/styles.scss';
@import '~shared_modules/xd-form-manager/src/xd-form-manager.scss';

.auth-form {
  width: 90%;
  max-width: 290px;
  margin: 30px auto 45px auto;

  a {
    color: $medium-blue-3;
    text-decoration: none;
  }
}

.auth-header-image {
  float: right;
  width: 120px;
}

.auth-header {
  margin: 0 0 30px 0;
  font-size: rem-calc(20px);
  font-weight: bold;
}

.auth-text {
  margin-top: 20px;
  font-size: rem-calc(14px);
  color: $slate-blue-2;
}

.auth-separator {
  background-color: rgba(153, 177, 191, 0.5);
  height: 1px;
  border: 0;
  margin: 2em 0 2em;
}

@include responsive('>=', 'phone') {
  .auth-form {
    max-width: 400px;
  }

  .auth-header-image {
    width: 150px;
  }
}

@include responsive('>=', 'tablet-small') {
  .auth-form {
    margin: 60px auto 120px auto;
  }

  .auth-header {
    margin-bottom: 60px;
    font-size: rem-calc(28px);
  }
}
