// @import '~ui-experience-system.settings';
@import '../../settings';
@import 'form-settings';
@import 'form-button';
@import 'cta-button';
@import 'secondary-button';

select::-ms-expand {
  display: none; // from http://stackoverflow.com/questions/20163079/remove-select-arrow-on-ie
}

// placeholder rules cannot be on same line, as browsers
// will disregard a whole rule if it doesn't understand
// any given selector within the rule

::-webkit-input-placeholder { // Chrome, Safari, Opera
  @include placeholder();
}

::-moz-placeholder { // Firefox
  @include placeholder();
}

:-ms-input-placeholder { // IE10-11
  @include placeholder();
}

::-ms-input-placeholder { // Edge
  @include placeholder();
}

// no focus ring for Firefox
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}

.form {
  margin: 0 auto;
  background-color: $white;
}

.form-control {
  display: block;
  width: 100%;
  max-width: 290px;
  margin: 0 auto 15px auto;

  &__label {
    height: 15px;
    margin-bottom: 5px;
    font-size: rem-calc(11px);
  }

  &__default,
  &__valid,
  &__invalid,
  &__active {
    @include disabledFormControlLabelState();
    transition: height $med_transition_time, opacity $med_transition_time;
  }

  &__default {
    @include enabledFormControlLabelState();
    text-transform: uppercase;
    color: $slate-blue-2;
  }

  &__input {
    display: block;
    width: 99%;
    height: 49px;
    border: none;
    border-bottom: 1px solid $dark-blue;
    padding: 10px 15px;
    font-size: rem-calc(19px);
    background-color: $white-marble;
    color: $dark-blue;

    &:focus,
    &:active {
      outline: 2px solid $dark-blue-2;
    }
  }

  select.form-control__input {
    appearance: none;
    -webkit-appearance: none; // appearance non-standard, autoprefixer won't do
    border-radius: 0;
    background: url('~shared_modules/ui-experience-system/forms/src/images/down-arrow.svg');
    background-position: 95% 50%;
    background-repeat: no-repeat;
    background-size: 11px;
  }

  &__icon {
    margin-left: 2px;
    vertical-align: middle;
  }

  &.is-valid {
    .form-control__icon {
      width: 9px;
      height: 6px;
    }

    .form-control__default {
      color: $form-valid;
    }

    .form-control__valid {
      @include enabledFormControlLabelState();
      color: $form-valid;
    }

    .form-control__input {
      background-color: $white;
      border-color: $lightest-gray-2;
    }
  }

  &.is-invalid {
    .form-control__icon {
      width: 2px;
      height: 10px;
      margin-top: -2px;
    }

    .form-control__default {
      @include disabledFormControlLabelState();
    }

    .form-control__invalid {
      @include enabledFormControlLabelState();
      color: $form-error-fg;
    }

    .form-control__input {
      color: $form-error-fg;
      background-color: $form-error-bg;
      border-color: $form-error-fg;
    }
  }

  &.is-active {
    .form-control__default {
      @include disabledFormControlLabelState();
    }

    .form-control__active {
      @include enabledFormControlLabelState();
      color: $dark-blue-2;
    }

    .form-control__input {
      background-color: transparentize($light-blue, 0.85);
    }
  }
}

@include responsive('>=', 'phone') {
  .form-control {
    max-width: 400px;
  }
}
