@import '~@shared_modules/propulsion/core/styles';
@import '~@shared_modules/propulsion/toolkit/tools';
@import '~@shared_modules/style-functions/functions.scss';

.footer-global-trusted-badge {
    padding: 24px 0;
    @include breakpoint('tablet-lg-and-up') {
        padding: 36px 0;
    }
    @include breakpoint('desktop-sm-and-up') {
        padding: 48px 0;
    }
    border-top: 1px solid #b6bec4;
    border-bottom: 1px solid #b6bec4;
    .logo-section {
        margin-top: rem(16px);
        @include breakpoint('desktop-sm-and-up') {
            margin-top: 0;
        }
        .footer-logo {
            width: rem(200px);
            height: rem(30px);
            background: url('~@shared_modules/components/footer-global/images/footer-logo.svg') no-repeat center;
            margin-bottom: rem(10px);
            display: block;
        }
        .footer-address {
            @include font('micro');
        }
        .footer-cta {  
            margin-top: rem(18px);
            width: 100%;
            min-width: rem(240px);
            @include breakpoint('tablet-md-and-up') {
                width: rem(240px);
            }
    
            &:focus {    
                background: #c22d13;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
            }
        }
    }
    .contact-section {
        @include font('micro');
        margin-top: 24px;
        @include breakpoint('tablet-md-and-up') {
            margin-top: 0px;
        }
        @include breakpoint('desktop-sm-and-up') {
            margin-top: 8px;
        }
        a {
            color: inherit;
        }
    }
    .copyright-section {
        text-align: center;
        margin-top: rem(24px);
        @include breakpoint('desktop-sm-and-up') {
            margin-left: rem(100px);
            margin-top: rem(12px);
        }
        .copyright-logo {
            height: rem(50px);
            background-repeat: no-repeat;
            background-position: center;
            margin: 15px auto;
            display: block;
            @include breakpoint('tablet-md-and-up') {
                display: inline-block;
                vertical-align: middle;
                margin: 0;
            }
        }
        .copyright-logo-equal-housing {
            background-image: url('~@shared_modules/components/footer-global/images/equal-housing.svg');
            width: rem(120px);
            @include breakpoint('tablet-md-and-up') {
                float: left;
            }
        }
        .copyright-logo-accredited-business {
            background-image: url('~@shared_modules/components/footer-global/images/accredited-business.svg');
            width: rem(99px);
        }
        .copyright-logo-veri-sign {
            background-image: url('~@shared_modules/components/footer-global/images/veri-sign.svg');
            width: rem(96px);
            @include breakpoint('tablet-md-and-up') {
                float: right;
            }
        }
        .footer-copyright {
            @include font('micro');
        }
    }
    .footer-sup {
        margin-left: 0;
        max-width: rem(629px);
        margin-top: rem(32px);
    }
}