@import '~@shared_modules/propulsion/core/styles.scss';
@import '~@shared_modules/propulsion/toolkit/tools';

$nav-letter-spacing: 0.6px;

.GlobalHamburguerButton {
  &__container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &--blue {
        background: $pds-color-denim-500;
    }
  }

  &__icon {
    font-size: 0;
    display: block;

    &-open {
      background-repeat: no-repeat;
      height: 23px;
      width: 23px;
      background-image: url('~@shared_modules/components/global-header/components/header-mobile/components/images/hamburgermenu.png');
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background-size: contain;
      background-position: 50%;
    }

    &-close {
      background-repeat: no-repeat;
      height: 23px;
      width: 23px;
      background-image: url('~@shared_modules/components/global-header/components/header-mobile/components/images/closed.png');
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      background-size: contain;
      background-position: 50%;
    }
  }
}
