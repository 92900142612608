@import '~shared_modules/ui-experience-system/settings/styles.scss';
@import '~shared_modules/xd-accordion/src/accordion.scss';

$true-white: #ffffff;

$zindex-nav: $zindex-high; // above most of page
$zindex-nav-secondary: $zindex-nav - 1; // below nav until overlapping or docked
$zindex-nav-secondary-docked: $zindex-nav + 1; // above nav when overlapping or docked
$zindex-nav-active: $zindex-nav-secondary-docked + 1; // main with activated panel goes over secondary menu
$zindex-nav-panel: $zindex-nav-active + 1;
$zindex-nav-panel-active: $zindex-nav-panel + 1;
$nav-background: $dark-blue;
$nav-menu-background: $true-white;
$nav-text-dark: $dark-blue;
$nav-text-light: $true-white;
$nav-text-highlight: $light-blue; // keep in sync with nav-secondary.scss
$nav-btn-background: $true-white;
$nav-btn-highlight-background: $light-primary-red; // keep in sync with nav-secondary.scss
$nav-height-mobile: 50px;
$nav-height-desktop: 56px; // keep in sync with nav-secondary.scss
$nav-mobile-margin: 15px; // distance from edges
$nav-letter-spacing: 0.6px; // xD-TODO: make shared variable with footer

$nav-desc: $dark-blue;
$nav-level-1-active: #64b7d3; // darker version of $light-blue on white background
$nav-level-1-hover: $primary-red;


@mixin link-chevron() {
  content: '';
  display: inline-block;
  width: 12px;
  height: 8px;
  margin: 0 0 2px 3px;
  background-image: url('~shared_modules/xd-site-header/src/images/chevron.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(-90deg);
}


/**********************************
 * nav
    - menu content = nav__content
    - actions (hamburger and close button) = nav__actions
 */
.nav {
  position: fixed;
  width: 100%;
  max-width: 100vw;         // fix for iOS8.4 not respecting "width: 100%""
  z-index: $zindex-nav;
  transform: translateX(0) translateY(0);
  transition: opacity $med_transition_time, transform $med_transition_time;
  opacity: 1;
  will-change: opacity;

  // nav--active
  &--active {
    z-index: $zindex-nav-active;
  }

  // nav--fadeout
  &--fadeout {
    opacity: 0;
  }

  // nav--shift-offscreen
  &--shift-offscreen {
    transform: translateY(-$nav-height-desktop);
  }

  // nav__background
  &__background {
    background-color: $nav-background;
    position: relative;
    color: $nav-text-light;
    font-size: rem-calc(12px);
  }

  // nav__container
  &__container {
    max-width: $page-max-width;
    margin-left: auto;
    margin-right: auto;
    min-height: $nav-height-mobile;

    // nav__container is-open-mobile
    .is-open-mobile & {
      height: 100vh; // will be changed by JS to exact window height
    }
  }

  // nav__shadow
  &__shadow {
    height: $nav-height-mobile;
  }

  // nav a
  & a,
  & a:active {
    text-decoration: none;
  }

  // nav__content
  &__content {
    display: none;        // jQuery controls displaying this
    position: absolute;
    top: $nav-height-mobile;
    width: 100%;
    height: 100%; // will be changed by JS to exact window height
    background-color: $nav-menu-background;
    color: $nav-text-dark;
    z-index: $zindex-nav-panel;
    transition: transform $med_transition_time;

    // for mobile, this will be added and removed because overflow-y:auto must be removed for tertiary menu to show up.  weird bug
    &--scrollable {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;    // momentum scrolling
      -webkit-transform: translate3d(0,0,0);  // attempt to fix z-index scrolling bug
    }

    .is-open-mobile &--mobile {
      display: block;
      background-color: $gray-marble;
    }

    .is-open-mobile-tertiary &--mobile {
      transform: translateX(-100%);
    }

    // nav__content--active
    &--active {
      z-index: $zindex-nav-panel-active;
    }
  }

  // nav__actions
  &__mobile-actions {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: rem-calc(11px);
    letter-spacing: $nav-letter-spacing;
    color: $white;
    padding: 7px 9px;
    border: solid 1px $light-slate-blue-2;
    cursor: pointer;

    .is-open-mobile & {
      display: block;
      color: $light-slate-blue-2;
    }
  }

  &__mobile-action-text {
    display: inline-block;
    vertical-align: middle;
    padding-right: 5px;
  }

  &__mobile-action-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
  }

  &__mobile-action-text--open,
  &__mobile-action-icon--open {
    .is-open-mobile & {
      display: none;
    }
  }

  &__mobile-action-text--close,
  &__mobile-action-icon--close {
    display: none;

    .is-open-mobile & {
      display: inline-block;
    }
  }

  // nav__product-img
  &__product-img {
    position: absolute;
    max-width: 36px;
    top: 50%;           // this and transform will center icon
    transform: translateY(-50%);
  }

  // nav__icon-link
  &__icon-link {
    display: block;
    position: relative;
    color: $nav-text-dark;
    font-size: rem-calc(14px);
    letter-spacing: $nav-letter-spacing;

    // nav__icon-link__text
    &__text {
      display: inline-block;
      padding: 10px 0 10px 50px;
      vertical-align: middle;   // for 'new' icon
    }
  }
}

@include responsive('>=', 'tablet-large') {   // TODO: show desktop style at smaller breakpoint, but need to tweak spacing
  .nav {

    // nav a:hover  (only hover on desktop)
    a:hover {
      color: $nav-text-highlight;
    }

    // nav__container
    &__container {
      padding: 18px 20px 0 20px;
      height: $nav-height-desktop;
    }

    // nav__shadow
    &__shadow {
      height: $nav-height-desktop;
    }

    // nav__content
    &__content {
      position: absolute;
      top: $nav-height-desktop;
      margin: 0;
      border: solid 1px $light-gray-2;
      border-bottom: none;
      box-shadow: 6px 6px 0 0 rgba(74, 98, 113, 0.1);
      height: auto;
      padding: 0;

      &--borrow {
        width: 850px;
        left: calc(50% - 425px);
      }

      &--invest {
        width: 425px;
        left: calc(50% - 213px);
      }
    }

    // nav__actions
    &__mobile-actions {
      display: none;
    }

    // nav__product-img
    &__product-img {
      max-width: 70px;      // these are smaller than the main heading icons
    }

    // nav__icon-link
    &__icon-link {

      // nav__icon-link__text
      &__text {
        padding: 30px 0 30px 105px;
      }

      // nav__icon-link__new
      &__new {
        vertical-align: top;
      }
    }
  }
}


/**********************************
 * nav-logo
 */
.nav-logo {
  float: left;
  padding: $nav-mobile-margin 0 15px $nav-mobile-margin;
  font-size: 0;           // prevent extra spacing around logo

  // nav-logo__img
  &__img {
    width: 127px;
    height: 19px;
  }

  @include responsive('>=', 'tablet-large') {   // TODO: show desktop style at smaller breakpoint, but need to tweak spacing
    padding: 0;

    &__img {
      width: 155px;
      height: 23px;
    }
  }
}


/**********************************
 * nav-tabs
 */
.nav-tabs {
  display: none;

  @include responsive('>=', 'tablet-large') {
    display: block;
    clear: both; // for floated icon above
    position: absolute;
    left: calc(50% - 125px);    // 250 width / 2
    top: calc(50% - 5px);       // 10px extra separator height / 2
    text-transform: uppercase;
    margin-top: -0.5em;
    width: 250px;

    &__items {
      display: flex;
    }

    &__item {
      flex: 0 1 50%;
      padding: 4px 0;
      text-align: center;
      cursor: pointer;

      &:first-child {
        text-align: right;
        padding-right: 20px;
        border-right: solid 1px lighten($slate-blue-2, 45%);
      }

      &:last-child {
        text-align: left;
        padding-left: 20px;
      }

      &--active {
        background-color: inherit;
      }

      // Flattened inner '&' for use by LCUI
      &--active-area .nav-tabs__title,
      &--active .nav-tabs__title {
        color: $nav-text-highlight;

        &:hover { /* stylelint-disable-line max-nesting-depth */
          color: $nav-text-highlight !important;    // argh, this needs to beat ".nav a"
        }
      }
    }

    &__title {
      display: inline-block;
      position: relative;
      color: $nav-text-light;
      font-size: rem-calc(16px);
      letter-spacing: $nav-letter-spacing;
      line-height: 1;
      vertical-align: middle;

      &:hover {
        color: $nav-text-light !important;        // argh, this needs to beat ".nav a"
      }
    }

    // Flattened inner '&' for use by LCUI
    .nav-tabs__item--active .nav-tabs__title:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: calc(50% - 20px);
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 17px solid transparent;
      border-right: 17px solid transparent;
      border-bottom: 15px solid $true-white;
      z-index: $zindex-nav-panel-active + 1; // to overlay the border of the nav__content border
    }

    &__arrow {
      display: inline-block;
      margin-left: 4px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid $true-white;
    }

    // Flattened inner '&' for use by LCUI
    .nav-tabs__item--active-area .nav-tabs__arrow {
      border-top: 6px solid $nav-text-highlight;
    }

    // Flattened inner '&' for use by LCUI
    .nav-tabs__item--active .nav-tabs__arrow {
      border-top: none;
      border-bottom: 6px solid $nav-text-highlight;
    }
  }
}

/**********************************
 * nav-tools (desktop)
 */
.nav-tools {
  display: none;
  padding: 15px 20px 15px 25px;
  background-color: $nav-background;
  text-transform: uppercase;

  // is-open-mobile nav-tools
  .is-open-mobile & {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
  }

  // is-open-mobile nav-tools__signin
  .is-open-mobile &__signin {
    float: right;
  }

  // nav-tools__cta-desktop
  &__cta-desktop {
    display: none;
    transition: opacity $med_transition_time;
  }

  @include responsive('>=', 'tablet-large') {   // TODO: show desktop style at smaller breakpoint, but need to tweak spacing
    display: block;
    float: right;
    margin: 0;
    padding: 0;
    background-color: initial;

    // nav-tools__cta-desktop
    &__cta-desktop {
      display: block;
    }

    // nav-tools__link
    &__link {
      display: inline-block;
      padding: 7px 10px;
      color: $nav-text-light;
      font-size: rem-calc(12px);
      line-height: 1;
      letter-spacing: $nav-letter-spacing;

      &--active {
        color: $nav-text-highlight;
      }

      &--last {
        padding-right: 0;
      }
    }
  }

  @include responsive('>=', 'desktop-small') {
    &__link {
      padding: 7px 15px;

      &--last {
        padding-right: 0;
      }
    }
  }
}

/**********************************
 * nav-panel (for desktop menu panel)
 */
.nav-panel {
  position: relative;
  font-size: rem-calc(16px);
  background-color: $nav-menu-background;

  // nav__panel a
  a {
    color: $nav-text-dark;
  }

  &__col {
    border-bottom: solid 5px transparent;

    &:after {
      content: '';
      position: absolute;
      top: 25px;
      height: calc(100% - 40px);  // 25px top + 15px bottom padding
      width: 1px;
      background-color: $light-gray-blue;
    }

    // Flattened inner '&' for use by LCUI
    &:first-child.nav-panel__col:after {
      display: none;
    }

    &:hover {
      border-bottom: solid 5px $nav-level-1-hover;
    }

    &--active,
    &--active:hover {
      border-bottom: solid 5px $nav-level-1-active;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__section {
    border-bottom: solid 1px $light-gray-blue;
    text-transform: initial;

    &:last-child {
      border: none;
    }
  }

  &__title {
    color: $nav-text-dark;
    font-size: rem-calc(17px);

    &:after {
      @include link-chevron();
    }
  }

  &__desc {
    color: $nav-text-dark;
    display: block;
    padding-top: 10px;
    font-size: rem-calc(16px);

    &:hover {
      color: $nav-text-dark;
    }
  }

  &__link {
    display: block;
    padding: 25px 20px 15px 20px;
  }

  // Flattened inner '&' for use by LCUI
  .nav-panel__col--active .nav-panel__link {
    cursor: default;
  }

  // Flattened inner '&' for use by LCUI
  .nav-panel__col:hover .nav-panel__title {
    color: $nav-level-1-hover;

    &:after {
      background-image: url('~shared_modules/xd-site-header/src/images/chevron-red.svg');
    }
  }

  // Flattened inner '&' for use by LCUI
  .nav-panel__col--active .nav-panel__title,
  .nav-panel__col--active:hover .nav-panel__title {
    color: $nav-level-1-active;

    &:after {
      display: none;
    }
  }
}

/**********************************
 * nav-mobile (for mobile menu panel)
 */
.nav-mobile {
  position: relative;
  font-size: rem-calc(16px);

  a {
    color: $nav-text-dark;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__section {
    border-bottom: solid 1px $light-slate-blue;
    letter-spacing: $nav-letter-spacing;
    text-transform: initial;
  }

  &__secondary-area {
    padding: 15px 0;
    background-color: $true-white;
  }

  &__desc {
    display: none;
  }

  &__title:after {
    @include link-chevron();
    margin: 0 0 2px 3px;
  }

  &__secondary-link {
    display: inline-block;
    padding: 15px 50px; // larger touch area
    line-height: 1;
    cursor: pointer; // required when applied to DIV

    &--chevron:after {
      @include link-chevron();
      margin: 0 0 2px 5px;
    }
  }

  &__tertiary-panel {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    padding: 5px 0;
    font-size: rem-calc(16px);
    background-color: $white;

    &--active {
      display: block;
    }
  }

  &__tertiary-breadcrumb {
    display: inline-block;
    padding: 15px 50px 15px 12px;
    margin-bottom: 15px;
    cursor: pointer; // required when applied to DIV

    &:before {
      @include link-chevron();
      transform: rotate(90deg);
      margin: 6px 10px 0 0;
      vertical-align: top;
    }
  }

  &__tertiary-link-area {
    display: inline-block;
    padding: 0 50px 0 20px;
    margin-bottom: 15px;
  }

  &__tertiary-title {
    display: block;
    padding-bottom: 5px;
    line-height: 1;
  }

  &__tertiary-desc {
    color: $slate-blue-2;
  }

  &__tertiary-link {
    display: inline-block;
    padding: 12px 20px;
  }

  // override normal accordion styles
  & [data-accordion-title] {
    height: 50px;
    padding: 17px 0 0 20px;
    background-color: $gray-marble;
    line-height: 1;
  }

  & .accordion-section--open [data-accordion-contents] {
    margin: 0;
    padding: 0;
  }

  &__tools {
    padding: 15px 0 100px 0;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  &__tools-link {
    display: inline-block;
    color: $nav-text-dark;
    list-style: none;
    padding: 12px 50px 12px 20px; // larger touch area
    line-height: 1;
    text-transform: uppercase;
    font-size: rem-calc(14px);
    letter-spacing: $nav-letter-spacing;
    cursor: pointer; // required when applied to DIV

    &--chevron:after {
      @include link-chevron();
      margin: 0 0 2px 5px;
    }
  }
}

// used when initially hiding the nav CTA for certain pages (initialHideNavCta=true)
.hide-cta {
  pointer-events: none;
  opacity: 0;
}
