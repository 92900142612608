@import '~shared_modules/ui-experience-system/settings/styles.scss';
@import '~shared_modules/ui-experience-system/forms/styles.scss';
@import '~shared_modules/xd-accordion/src/accordion.scss';

// move to UIXS
// ---------------------------------
@mixin selection() {
  background: $light-blue;
  color: $white;
}

$footer-blue: $medium-blue-3;
$footer-border: 2px solid transparentize($footer-blue, 0.7);

$nav-letter-spacing: 0.6px;     // xD-TODO: make shared variable with header

// selection and -moz-selection can't be on same rule,
// or browsers invalidate both if they don't understand just 1

::-moz-selection {
  @include selection();
}

::selection {
  @include selection();
}
// end move to UIXS
// ---------------------------------


.footer {
  background-color: $dark-blue;
  color: $white;

  &__image {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    fill: $dark-blue;
  }

  //--------------------------
  //--  links & nav lists  ---
  //--------------------------

  &__body {
    a {
      color: $white;
    }
  }

  &__nav-item-list {
    margin: 0 0 30px 0;
    padding: 0;
    list-style-type: none;
  }

  &__nav-item {
    padding: 0 10px;
    font-size: rem-calc(14px);
    line-height: 1.5;
    letter-spacing: $nav-letter-spacing;

    a {
      text-decoration: none;
      color: $footer-blue;

      &:hover {
        color: $white;
      }
    }

    &--cta + &__nav-item {
      padding-bottom: 40px;
    }

    &--cta > a {
      color: $primary-red;
    }

    &--cta > a:after {
      content: '';
      display: inline-block;
      width: rem-calc(10px);
      height: rem-calc(10px);
      border-right: 2px solid $primary-red;
      border-top: 2px solid $primary-red;
      transform: rotate(45deg);
      margin-left: 2px;
    }

    &--cta > a:hover:after {
      border-color: $white;
    }
  }

  &__nav-item:not(.hidden) + .footer__nav-item {
    padding-top: 12px;
  }

  &__nav-section-title {
    margin: 20px 0 20px 20px;
    font-size: rem-calc(16px);
    line-height: 1.52;
    text-transform: uppercase;
    letter-spacing: $nav-letter-spacing;
  }

  &__nav {
    margin: 0 30px;
  }

  [data-accordion-section] {
    border-bottom: $footer-border;
  }

  &__company-social {
    border-top: $footer-border;
    border-bottom: $footer-border;
  }

  &__mail-area {
    border-bottom: $footer-border;
  }

  &__facebook-area {
    border-right: $footer-border;
  }

  &__nav-category {
    margin-bottom: 15px;
    font-size: rem-calc(14px);
    text-transform: uppercase;
    line-height: 1.5;
  }

  &__nav-subcategory {
    font-size: rem-calc(12px);
    text-transform: uppercase;
    line-height: 1.27;

    a {
      color: $white;
    }
  }

  &__flexcontainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .text-static--space {
    margin-top: rem-calc(20px);
  }

  //--------------------------
  //--  logo, addr, social  --
  //--------------------------

  &__address {
    text-align: center;
    font-style: normal;
    font-size: rem-calc(16px);
    line-height: 1.5;
  }

  &__company-cta {
    margin: 25px 30px 20px 30px;
    text-align: center;
  }

  &__company-social {
    margin-top: 30px;

    a {
      text-decoration: none;
    }
  }

  &__mail {
    margin: 20px 0;
    font-size: rem-calc(14px);
    line-height: 1.5;
    align-self: center;
  }

  &__image--mail {
    width: 24px;
    height: 20px;
    vertical-align: middle;
    margin-right: 5px;
    background-image: url('~shared_modules/xd-site-footer/src/images/email.svg');
    background-size: contain;
  }

  &__image--facebook {
    width: 24px;
    height: 24px;
    margin: 35px auto;
    background-image: url('~shared_modules/xd-site-footer/src/images/fb.svg');
  }

  &__image--twitter {
    width: 24px;
    height: 24px;
    margin: 35px auto;
    background-image: url('~shared_modules/xd-site-footer/src/images/twitter.svg');
  }

  &__image--lc-logo {
    display: block;
    width: 100%;
    max-width: 234px;
    height: 0;
    padding-bottom: 14.54%; // maintains 41/282 aspect ratio
    margin: 25px auto 0 auto;
    background-image: url('~shared_modules/xd-site-footer/src/images/LC_logo_reverse.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  //--------------------------
  //-----  tertiary nav  -----
  //--------------------------


  &__tertiary-links {
    margin: 0;
    padding: 40px 0 40px 10px;

    font-size: rem-calc(14px);
    line-height: 1.5;
    color: $footer-blue;

    &:after {
      width: 50%;
    }

    li {
      float: left;
      display: inline-block;
      width: 50%;
      font-size: rem-calc(14px);
    }

    li + li {
      padding-top: 0 !important; // override .footer__nav-item rules. TODO: remove important?
    }

    a {
      color: $footer-blue;
      padding: 15px 5px 15px 0;
      display: inline-block;
    }
  }

  //--------------------------
  //------  trust  bar  ------
  //--------------------------

  &__trust-badge-bar {
    font-size: rem-calc(13px);
    background-color: $white-marble;
    color: $dark-blue;
    padding: 30px 0;
  }

  &__trust-item {
    text-align: center;
  }

  &__trust-image {
    width: 150px;
  }

  //--------------------------
  //-----  disclaimers  ------
  //--------------------------

  &__disclaimers {
    background-color: $dark-blue;
    color: $white;

    [data-accordion-title] {
      cursor: pointer;
    }

    [data-accordion-section] {
      border-bottom: none;
    }

    a {
      color: $white;
    }
  }

  &__disclaimers-title {
    padding: 30px 0 30px 30px;
    text-transform: uppercase;
    font-size: rem-calc(14px);
    letter-spacing: $nav-letter-spacing;
  }


  &__disclaimers-content {
    padding: 10px 30px 40px 45px;
    font-size: rem-calc(14px);
    line-height: 1.5;
    color: $light-gray-blue;
  }

  &__disclaimer {
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;

    li:before {
      position: absolute;
      top: 0;
      left: -17px;
      font-size: 1.5em;
    }
  }

  &__disclaimer + &__footnotes {
    padding-top: 1em;
  }

  &__footnotes {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &:first-child {
      counter-reset: customlistcounter;
    }

    li {
      word-break: break-word;
      word-wrap: break-word;
      counter-increment: customlistcounter;
      margin-left: -5px;
      padding-left: 5px;
      width: 95%;

      @include responsive('>=', 'phone-large') {
        width: 99%;
      }
    }

    li:before {
      content: counter(customlistcounter) ' ';
      position: relative;
      display: inline-block;
      text-align: right;
      left: -10px;
      width: 20px;
      margin-left: -20px;
    }

    li + li {
      padding-top: 1em;
    }
  }

  //-------------------------------------
  //------  cta button (overrides) ------
  //-------------------------------------
  .cta-button__cta {
    font-size: 18px;
    line-height: 50px;
    height: 50px;
  }

  .cta-button__action {
    width: 50px;
  }

  .footer__nav-item-img {
    display: block;
    margin-top: 8px;
    height: 22px;
    max-width: 100%;
    border-style: none;
  }

  @include responsive('>=', 'phone-small') {
    &__tertiary-links {
      padding: 40px 0 40px 30px;
    }
  }

  @include responsive('>=', 'phone-large') {
    padding: 40px 0;

    &__nav {
      margin: 0;
    }

    &__mail-area {
      border-bottom: none;
      border-right: $footer-border;
    }

    &__company-logo {
      border-top: $footer-border;
    }

    [data-accordion-section] {
      border-bottom: none;
    }

    &__disclaimers-content {
      padding: 40px 30px 40px 45px;
    }

    &__tertiary-links {
      li {
        padding: 0;
        width: 33%;
      }

      li > a {
        padding: 15px 0 15px 40px;
      }
    }

    &__mail {
      margin: 35px auto;
    }
  }

  @include responsive('>=', 'tablet') {
    &__nav {
      margin: 0 10px;
    }

    &__nav-item {
      padding-top: 20px;
    }

    &__nav-subcategory {
      padding-top: 25px;
    }

    &__image--mail {
      width: 22px;
      height: 16px;
    }

    &__image--facebook,
    &__image--twitter {
      width: 22px;
      height: 22px;
      margin: 20px 20px 20px 0;
    }

    &__trust-badge &__image {
      display: inline-block;
    }

    &__company-logo {
      border: none;
    }

    &__image--lc-logo {
      margin: 15px 0;
    }

    &__address {
      text-align: left;
    }

    &__company-cta {
      text-align: left;
      margin: 25px 30px 0 0;
    }

    &__mail-area {
      width: auto;
    }

    &__company-social,
    &__mail-area,
    &__facebook-area {
      border: none;
    }

    &__mail {
      margin: 20px 20px 20px 0;
    }

    &__nav-section-title:after {
      position: static;
      display: none;
    }

    &__tertiary-links {
      padding: 30px 0;
      text-align: center;

      li {
        padding: 0;
        width: 20%;
      }

      li > a {
        line-height: 1;
        padding: 0;
        border-right: 1px solid transparentize($light-blue, 0.7);
        width: 100%;
        margin-top: 20px;
      }

      li:last-of-type a {
        border-right: none;
      }
    }

    &__trust-badge-bar {
      padding: 5px 0;
    }

    &__trust-image {
      width: 118px;
    }

    &__address {
      text-align: left;
      font-size: rem-calc(16px);
    }

    &__company-social {
      margin-top: 10px;
    }
  }

  @include responsive('>=', 'tablet-large') {
    &__tertiary-links {
      padding: 30px 0;

      li {
        padding: 0;
        width: 20%;
      }
    }
  }

  @include responsive('between', 'tablet', 'tablet-large') {
    &__nav-new-badge {
      margin-left: 0;
      margin-top: 4px;
    }
  }

  @include responsive('>', 'desktop') {
    &__tertiary-links li {
      width: auto;

      a {
        padding: 0 16px;
      }
    }

    .text-static--space {
      margin-top: rem-calc(30px);
    }
  }
}
