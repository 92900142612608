@import '~@shared_modules/propulsion/core/styles';
@import '~@shared_modules/propulsion/toolkit/tools';

.header {
  .sign-in-button {
    min-width: 86px;
    height: 42px;
    margin: 14px 0 15px 28px;

    &:focus {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    }
  }

  .GlobalHeaderMobile__wrapper {
    .sign-in-button {
      margin-left: auto;
      margin-right: 16px;

      @include breakpoint('phone-lg-and-up') {
        margin-right: 32px;
      }
    }
  }
}

.menu {
  list-style-type: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity, transform, visibility;
  position: absolute;
  top: 72px;
  right: 0;
  width: 100%;

  @include breakpoint('phone-lg-and-up') {
    right: 70px;
    width: 272px;
  }

  @include breakpoint('tablet-md-and-up') {
    right: 17px;
  }
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.nav-dropdown-redesign {
  background-color: #ffffff;
  z-index: 1;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.nav-dropdown-redesign {
  width: 100%;

  li:last-child {
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin-bottom: 16px;

    a {
      width: 100%;
      min-width: 240px;
      height: 60px;
      color: $pds-color-denim-500;
      border-color: $pds-color-denim-500;
      justify-content: left;
      background-image: url('~@shared_modules/components/global-header/images/back-button.svg');
      background-repeat: no-repeat;
      background-position: right 24px center;
      text-decoration: none;
      @include breakpoint('phone-lg-and-up') {
        width: 240px;
      }
    }

    a:hover,
    a:focus {
      color: $pds-color-denim-300;
      border-color: $pds-color-denim-300;
    }
  }
}

.has-submenu {
  button.link {
    width: 100%;
    min-width: 240px;
    height: 60px;
    justify-content: left;
    color: $pds-color-denim-500;
    border-color: $pds-color-denim-500;
    padding-right: 3rem;
  }

  button.link::after {
    content: '';
    position: absolute;
    background-image: url('~@shared_modules/components/global-header/images/back-button.svg');
    background-repeat: no-repeat;
    width: 18px;
    height: 14px;
    right: 24px;
  }

  button.open {
    border-bottom: none;
    box-shadow: none;
  }

  button.open::after {
    background-image: url('~@shared_modules/components/global-header/components/header-desktop-wsn/images/dropdown.png');
    background-size: 18px;
    height: 11px;
  }
}

.sub-menu {
  list-style-type: none;
  display: none;
  border: 1px solid;
  color: $pds-color-denim-500;
  border-color: $pds-color-denim-500;
  border-top: none;
  
  .nav-dropdown-redesign-sub{
    list-style: none;
    margin-left: 16px;
    li {
      margin-bottom: 12px;
    }
  }

  .sublink {
    background: none;
    color: $pds-color-denim-500;
    @include font-type('text-regular');
  }

  .sublink:hover {
    color: $pds-color-cardinal-500;
    border-color: $pds-color-denim-300;
    @include font-type('text-bold');
  }
}

.sub-menu.activeSub {
  display: block;
}

.sub-menu.inActiveSub {
  display: none;
}

