@import '~@shared_modules/propulsion/core/styles';
@import '~@shared_modules/propulsion/toolkit/tools';
@import '~@shared_modules/style-functions/functions.scss';

.footer-global {
    color: $dark-blue; 
    overflow: auto;
    
    .footer__section__grey {
        background-color: #f6f9f9;
    }

    .footer__container {
        padding: 0 1.25rem;
        max-width: 1200px;
        margin: 0 auto;
    }

    .footer-newletter-section {
        @include breakpoint('tablet-md-and-up') {
            max-width: rem(363px);
            margin: 0 0 0 auto;
        }
    }

    .footer__disclaimer-container {
        .disclaimer-custom {
            margin: 3rem 0 1rem 0;
            font-size: 14px; //This should be removed in the dynamic version
        }
        p {
            font-size: 14px; //This should be removed in the dynamic version
        }
    }
}