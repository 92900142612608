@import '~shared_modules/ui-experience-system/settings/styles.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 0;
  overflow: hidden;
  outline: none;
  -webkit-overflow-scrolling: touch;
  z-index: $zindex-hidden;


  &__dimmer {
    background-color: $prequalbackground-blue;
    opacity: 0;
    transition: all $short_transition_time;
    position: fixed;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
  }

  &__dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    // let content dictate width but keep in bounds
    max-width: 900px;
    min-width: 300px;
    // viewport units for better browsers
    /* stylelint-disable declaration-block-no-duplicate-properties */
    width: 800px;
    max-height: 99%;  // 99vh doesn't work in iphone5
    width: 95vw;
    /* stylelint-enable */
    overflow: auto;
    visibility: hidden;
    transform: translateX(-50%) translateY(-90%);
    opacity: 0;
    -ms-overflow-style: none;  // IE 10+
  }

  &__dialog::-webkit-scrollbar {
    background-color: transparent;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 15px;
    color: $white;
  }

  &__title {
    font-size: rem-calc(24px); // xD-TODO

    img {
      vertical-align: baseline;
    }
  }

  &__subtitle {
    font-size: rem-calc(13px); // xD-TODO
    padding: 5px 0 0 25px;
  }

  &__close {
    width: 25px;
    height: 25px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: svg-url('<svg width="34" height="34" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><path d="M19.06 17.042L33.104 2.996a1.361 1.361 0 0 0 0-1.928 1.363 1.363 0 0 0-1.93 0L17.13 15.112 3.086 1.068a1.363 1.363 0 0 0-1.93 0 1.361 1.361 0 0 0 0 1.928L15.2 17.042 1.157 31.086a1.363 1.363 0 0 0 .964 2.33c.35 0 .699-.135.965-.4L17.13 18.97l14.044 14.044a1.36 1.36 0 0 0 1.93 0 1.363 1.363 0 0 0 0-1.929L19.06 17.042z" fill="#FFF" fill-rule="evenodd"/></svg>');
    cursor: pointer;
  }

  &__content {
    padding: 20px 15px;
    background-color: $white;
    border: 1px solid $white;
    border-radius: 4px;
  }

  // animate the modal in
  &--showing {
    max-height: none;
    z-index: $zindex-highest;

    .modal__dialog {
      visibility: visible;
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
      transition: all $med_transition_time;
    }

    .modal__dimmer {
      visibility: visible;
      opacity: 0.95;
      top: 50%;
      width: 100%;
      height: 100%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  // animate the modal out
  &--hiding {
    max-height: none;
    z-index: $zindex-highest;
    user-select: none;
    pointer-events: none;

    .modal__dialog {
      visibility: visible;
      transform: translateX(-50%) translateY(-80%);
      opacity: 0;
    }

    .modal__dimmer {
      visibility: visible;
      opacity: 0;
      top: 50%;
      left: 50%;
      width: 0;
      height: 0;
    }

    * {
      pointer-events: none;
      user-select: none;
    }
  }

  @include responsive('>=', 'phone-large') {
    &__dialog {
      width: 90vw;
      max-height: 93vh;
    }
  }

  @include responsive('>=', 'tablet-small') {
    &__content {
      padding: 30px 25px;
    }

    &__header {
      padding-top: 30px;
    }

    &__title {
      font-size: rem-calc(34px);
    }

    &__subtitle {
      font-size: rem-calc(16px);
    }

    &__close {
      width: 30px;
      height: 30px;
    }
  }

  @include responsive('>=', 'desktop-small') {
    &__content {
      padding: 40px 25px;
    }

    &__title {
      font-size: rem-calc(40px); // xD-TODO
    }

    &__subtitle {
      font-size: rem-calc(18px); // xD-TODO
    }

    &__close {
      width: 35px;
      height: 35px;
    }
  }
}
