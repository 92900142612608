// secondary-button                         (blue background button)
// secondary-button secondary-button--white (white background button with blue text)
// secondary-button secondary-button--clear (transparent clear background button with blue text)

$secondary-btn-font: 14px;

.secondary-button {
  display: inline-block;
  height: $secondary-btn-height;
  min-width: 290px;
  padding: (($secondary-btn-height - $secondary-btn-font)/2) 20px;
  font-size: rem-calc($secondary-btn-font);
  line-height: 1;
  color: $white;
  background-color: $dark-blue-2;
  cursor: pointer;
  text-decoration: none;

  @at-root .fonts-loaded & {
    font-family: $webfont-font-family-bold;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: darken($dark-blue-2, 5%);
  }

  &:active {
    color: $white;
    background-color: lighten($dark-blue-2, 10%);
  }

  &--white {
    color: $dark-blue-2;
    background-color: $white;
    border: 1px solid $dark-blue-2;

    &:hover,
    &:focus {
      color: $dark-blue-2;
      background-color: darken($white, 5%);
    }

    &:active {
      color: $dark-blue-2;
      background-color: darken($white, 10%);
    }
  }

  &--clear {
    color: $white;
    background-color: transparent;
    border: 2px solid $white;

    &:hover,
    &:focus {
      color: $white;
      background-color: rgba(256,256,256, 0.1);
    }

    &:active {
      color: $white;
      background-color: rgba(256,256,256, 0.2);
    }
  }
}
