.GlobalHeaderLogo {
  padding: 23px 0px;
  &__link {
    display: flex;
  }

  &__img {
    &--white,
    &--blue {
      background-repeat: no-repeat;
      width: 152px;
      height: 22px;
    }
  }

  &__img {
    &--white {
      background-image: url('~@shared_modules/components/global-header/components/header-logo/images/lendingclub-logo-white.svg');
    }
    &--blue {
      background-image: url('~@shared_modules/components/global-header/components/header-logo/images/lendingclub-logo.svg');
    }
  }

  @media screen and (min-width: 768px) {
    &__img {
      &--blue {
        width: 152px;
        height: 22px;
        background-position-x: center;
      }
    }
    padding: 0px;
  }

  @media screen and (min-width: 1200px) {
    &__img {
      &--blue {
        width: 184px;
        height: 27px;
        background-position-x: center;
      }
    }
    padding: 0px;
  }
}
