
@import '~@shared_modules/style-functions/functions.scss';

.DataTable {
  background-color: $pds-color-iceberg-500;
  border-collapse: collapse;
  border: 1px solid $pds-color-stainless-500;
  color: $pds-color-denim-500;
  font-size: rem(12px);
  text-align: left;
  width: 100%;

  &__head {
    background-color: $pds-color-stainless-500;
  }

  &__cell {
    border-bottom: 1px solid $pds-color-stainless-500;
    padding: 8px;
    vertical-align: top;

    &--header {
      font-weight: bold;
      text-align: left;
    }

    ul {
      margin: 0;
      padding-left: 16px;
    }
  }

  &__cell:first-child {
    font-weight: bold;
  }

  @include breakpoint('tablet-md-and-up') {
    font-size: rem(16px);

    &__cell {
      padding: 16px;
    }

  }
}
