// @import '~ui-experience-system.settings';
@import '../../settings';
// @import '~reflex-grid/reflex';
@import './node_modules/reflex-grid/reflex';

// ---------------------------------
// ----------  layout  -------------
// ---------------------------------

@include breakpointBodyLabels();

//
// PAGE LAYOUT
//
.page-header,
.page-content,
.page-footer {
  @include pageContentContainer();
}

// a narrow variant of 850px when tablet+ width
.page-content--narrow {
  @include responsive('>=', 'tablet') {
    max-width: 850px + map-get($page-side-padding, 'tablet') * 2;
  }
}

.page-content--bleed {
  padding-left: 0;
  padding-right: 0;
}

//
// LAYOUT HELPERS
//
.relative {
  position: relative;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.list-horizontal {
  /* xD-TODO: is this the correct place to put this? */
  @extend .clearfix;
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    float: left;
    display: block;
    padding: 0;
    margin: 0;
  }
}
