@import './styles/themes/default.scss';

body {
  &.is-open-mobile-menu  {
    overflow: hidden;
  }

  .GlobalHeaderMobile__logo--partner {
      padding-bottom: 8px;
  }

  .auth-banner-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(305deg, #1A4A7E 32%, #0278B3 92.7%);
    padding: 2rem;
    gap: 1rem;
    position: relative;
    .auth-banner__close {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
      background: url('~@shared_modules/components/global-header/components/header-mobile/images/x-close.svg') no-repeat;
    }
    .auth-banner__info { 
      gap: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #fff;
      .auth-banner__title {
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 700;
        color: #fff;
        line-height: 1.875rem;
        sup {
          vertical-align: middle;
          font-size: 1rem;
          font-weight: 900;
        }
      }
      .auth-banner__subtitle {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;
        color: #fff;
        line-height: 1.125rem;
      }
    }
    .auth-banner__img {
      gap: 1rem;
      display: flex;
      justify-content: center;
      .auth-banner__apple-store {
        background: url('~@shared_modules/components/global-header/components/header-mobile/images/apple-store-badge.svg') no-repeat;
        height: 40px;
        width: 120px;
      }
      .auth-banner__google-play {
        background: url('~@shared_modules/components/global-header/components/header-mobile/images/google-play-badge.svg') no-repeat;
        height: 40px;
        width: 136px;
      }
    }
  }
  @include breakpoint("tablet-md-and-up") {
    .auth-banner-mobile {
      display: none;
    }
  }
}
