@import '~@shared_modules/propulsion/core/styles.scss';
@import '~@shared_modules/propulsion/toolkit/tools';
@import "~@ui-propulsion/core/_typography";

.Globalmain-header{
  padding-bottom: 72px;
  transition: padding .5s;
  &.hasBothHeaders {
    padding-bottom: 144px;
    transition: padding .5s;
  }
}

.GlobalsecondaryNavWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-medium-high;
    transform: translateY(72px);
    transition: transform 0.5s;
    &.docked {
      position: fixed;
      transform: translateY(0px);
      transition: transform 0.5s;
    }
}

.GlobalHeaderDesktop {
  background-color: #ffffff;
  max-width: 100vw;
  min-height: 72px;
  position: fixed;
  width: 100%;
  z-index: $zindex-high;
  transform: translateY(0px);
  transition: transform 0.5s;
  border-bottom: 1px solid #EBEAEB;

  &.hidden {
    transform: translateY(-73px);
    transition: transform 0.5s;
  }

  &__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 0 1.25rem;
    position: relative;
  }

  &__logo {
    float: left;
    padding: 23px 0px;

    &--partner {
      float: right;
    }
  }

  &__dropdown {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .HeaderLogo {
    &__img {
      height: 27px;
      width: 184px;
    }
  }
}

.HeaderNav__nav {
  float: right;
}
.HeaderNav__nav ul.nav-list {
  list-style: none;
  margin: 0 0 0 12px;
  display: inline-block;
  padding: 0px 12px;
}
.HeaderNav__nav ul.nav-list li.collapsed {
  position: relative;
}
.HeaderNav__nav ul.nav-list li.collapsed label {
  display: block;
  text-decoration: none;
  color: $pds-color-denim-500;
  @include font(
  'section-label',
  (
    'line-height': false
  )
  );
  line-height: 18px !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  padding: 27px 8px 21px 0px;
  border-bottom: 7px solid transparent;
  cursor: pointer;
}
.HeaderNav__nav ul.nav-list li.collapsed ul li.collapsed label {
border-bottom: none;
padding: 11px 0;
}
.HeaderNav__nav ul.nav-list li a {
  display: block;
  text-decoration: none;
  color: $pds-color-denim-500;
  @include font(
  'section-label',
  (
    'line-height': false
  )
  );
  line-height: 18px !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
  padding: 27px 0px 21px;
  border-bottom: 7px solid transparent;
}
.HeaderNav__nav ul.nav-list li:hover a, .HeaderNav__nav ul.nav-list li.collapsed:hover label, .HeaderNav__nav ul.active li.collapsed label {
  border-bottom: 7px solid #df3416;
  text-decoration: none;
}
.HeaderNav__nav ul.nav-list li.collapsed ul li.collapsed:hover label {
  border-bottom: none;
  color: #df3416;
}
.HeaderNav__nav ul.nav-dropdown li a {
  border-bottom: none !important;
}
.HeaderNav__nav ul.nav-list li ul li:hover a {
    border-bottom: none;
}
.HeaderNav__nav li.leaf.active a {
  color: $pds-color-cardinal-500 !important;
  @include font-type('text-bold');
}
.HeaderNav__nav ul.nav-list li.collapsed label:after {
  background: url('~@shared_modules/components/global-header/components/header-desktop-wsn/images/dropdown.png');
  position: absolute;
  content: '';
  top: 50%;
  right: -10px;
  width: 10px;
  height: 6px;
  margin-top: -3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  left: auto;
}
.HeaderNav__nav ul.nav-list li.collapsed ul li.collapsed label:after {
  right: 0;
  transform: rotate(-90deg);
}
.HeaderNav__nav ul.nav-list li.collapsed ul li.collapsed:hover label:after {
  transform: rotate(90deg);
}
.HeaderNav__nav .nav-dropdown li.leaf a:hover {
color: $pds-color-cardinal-500;
@include font-type('text-bold');
}
.HeaderNav__nav ul.nav-list li ul li a {
  padding: 11px 0;
  border-bottom: none;
  color: #0A3961;
  @include font("section-label");
  @include font-type('text-regular');
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.HeaderNav__nav ul.nav-list li:hover > ul.nav-dropdown, .HeaderNav__nav ul.nav-list li:focus > ul.nav-dropdown, .HeaderNav__nav ul.nav-list li:focus-within > ul.nav-dropdown {
  display: block !important;
}

.HeaderNav__nav .nav-dropdown {
  position: absolute;
  z-index: 1;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.1);
  display: none;
  background-color: #ffffff;
  left: -12px;
  margin-left: 0px;
  padding: 11px 15px 12px 16px;
  list-style-type: none;
  min-width: 200px;
}
.HeaderNav__nav ul.nav-list li ul li ul.nav-dropdown {
  right: auto;
  left: 161px;
  top: 0px;
}
.sign_btn {
  padding: 15px 0px 0px 0px;
}
.sign_btn a {
  color: $pds-color-cardinal-500 !important;
  border: 1px solid #df3416 !important;
  padding: 12px 15px 12px 16px !important;
}
.sign_btn a:hover {
  border-bottom: 1px solid;
  text-decoration: none;
}

.HeaderNav__nav {
  .muli-child-dropdown {
    width: max-content;
    padding: 24px 0;
    left: -142px;
    .muli-child-column {
      display: table-cell;
      padding: 0 32px;
      border-left: 1px solid #C8CFD4;
      h5 {
        @include font('section-label');
      }
      ul {
        margin: 10px 0 0 0;
        padding: 0;
        min-width: 170px;
      }
    }
    .muli-child-column:first-child {
      border: none;
    }
  }
}
