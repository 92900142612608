@import '~shared_modules/ui-experience-system/settings/styles.scss';

.prequal-sb {
  color: $prequalbackground-blue;

  &__headline {
    max-width: 280px;
    margin: 0;
    font-weight: bold;
    text-align: center;
    font-size: rem-calc(19px);
    padding: 0 5px;
    color: $dark-blue;
  }

  &__subheadline {
    margin: 15px 15px 20px;
    font-size: rem-calc(16px);
    font-weight: normal;
    color: $slate-blue-2;
  }

  &__texts {
    padding: 20px 15px;
  }

  &__section {
    position: relative;
    padding-bottom: 0; // to give room for button minus bottom padding on &__texts above
    background-color: $gray-marble;
  }

  &__section-header {
    color: $light-primary-red;
    position: relative;
    margin: 0 0 15px;
    font-size: rem-calc(16px);
    cursor: pointer;
    text-align: left;
    line-height: 1.5;

    &:after {
      position: absolute;
      content: '';
      margin-left: 20px;
      margin-top: 6px;
      width: 10px;
      height: 12px;
      // xD-TODO: combine with ui-accordion into svg lib
      background-image: url('~shared_modules/xd-prequal-modal/src/images/chevron.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__desc {
    font-size: rem-calc(14px);
    margin-bottom: 0;
    text-align: left;
    color: $slate-blue-2;
    line-height: 1.4;
  }

  &__business {
    margin-top: 20px;
  }

  &__button {
    position: relative;
    bottom: 0;
    display: block;
    width: 100%;
    height: 40px;
    padding: 10px;
    border: none;
    color: $white;
    background-color: $medium-blue-2;
    cursor: pointer;
    text-decoration: none;
    transition: background-color $short_transition_time;
    vertical-align: middle;
    font-size: rem-calc(12px);

    &:hover {
      text-decoration: none;
      background-color: $prequalbackground-blue;
    }
  }

  &__button-text {
    width: 100%;
    max-width: 225px;
    margin: 0 auto;
    line-height: 1.33;
  }

  &__business &__button {
    padding: 13px 15px; // more padding to better vertically align text
  }

  @include responsive('>=', 'phone-small') {
    &__headline {
      margin: 0 auto;
    }

    &__section-header {
      margin: 0;
      padding-bottom: 5px;
    }

    &__desc {
      margin-bottom: 0;
    }

    &__button {
      position: absolute;
      height: 60px;
      font-size: rem-calc(14px);
      margin-bottom: 0;
    }

    &__business {
      margin-top: 10px;
    }

    &__business &__button {
      margin-bottom: 0;
      padding: 19px 15px; // more padding to better vertically align text
    }

    &__subheadline {
      margin: 10px 15px 15px;
    }

    &__texts {
      padding: 7px 15px;
    }
  }

  @include responsive('>=', 'tablet-small') {
    padding: 40px 0;

    &__headline,
    &__subheadline {
      max-width: none;
    }

    &__headline {
      font-size: rem-calc(24px);
      line-height: 1.3333;
    }

    &__subheadline {
      margin: 10px 15px 60px;
    }

    &__container {
      display: flex;
    }

    &__texts {
      padding: 0 25px 10px;
      max-width: 290px;
    }

    &__section {
      flex: 0 0 50%;
      margin: 0;
      border: none;
      text-align: center;
      background: none;
    }

    &__section-header {
      position: static;
      margin-bottom: 25px;
      padding-bottom: 0;
      border: none;
      font-size: rem-calc(19px);
      line-height: 1.3684;
    }

    &__desc {
      font-size: rem-calc(16px);
    }

    &__personal {
      padding-right: 25px;
    }

    &__personal > div {
      padding: 0;
      float: right;
    }

    &__business {
      border-left: 1px solid $light-slate-blue;
      padding-left: 25px;
    }

    &__business > div {
      padding: 0;
      float: left;
    }

    &__button {
      left: 10%;
      width: 80%;
    }

    &__section-header:after {
      margin-left: 10px;
    }
  }

  @include responsive('>=', 'desktop') {
    padding: 40px 0;

    &__personal {
      padding-right: 35px;
    }

    &__business {
      padding-left: 35px;
    }

    &__section-header:after {
      margin-left: 20px;
    }
  }
}
