////
/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
/// @group layout-helper
////

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-hidden: -1;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-base: 1;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-low: 25;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-medium: 50;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-medium-high: 75;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-high: 100;

/// Math OK on $zindex variables, e.g. $zindex-low + 1, etc.
/// @type Number
$zindex-highest: 10000;
