@import '~shared_modules/ui-experience-system/settings/styles.scss';

/*
accordion expects this structure:
  .accordion
    [data-accordion-section]
      [data-accordion-title]
      [data-accordion-contents]
    [data-accordion-section]
      [data-accordion-title]
      [data-accordion-contents]
*/

.accordion {
  [data-accordion-contents] {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all $med_transition_time;
  }

  [data-accordion-title] {
    position: relative;
    cursor: pointer;
    padding-right: 45px; // compensate for width + margin-right of chevron in :after

    &:after {
      position: absolute;
      content: '';
      top: 50%;
      right: 20px;
      width: 12px;
      height: 8px;
      margin-top: -4px;
      transition: all $med_transition_time;
      background-image: url('~shared_modules/xd-accordion/src/images/chevron.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  
  &--inverse [data-accordion-title]:after {
    background-image: url('~shared_modules/xd-accordion/src/images/chevron-inverse.svg');
  }

  .accordion-section--open [data-accordion-title]:after {
    transform: scaleY(-1);
  }

  &-section--open [data-accordion-contents] {
    // max-height will be adjusted via JS
    opacity: 1.0;
    margin-bottom: getResponsiveFactoryValue($spacings, med);
  }
}
