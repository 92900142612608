// .cta-button                         height: base 46px --> phone-small 55px --> tablet 60px  default behavior is full width at all breakpoints
// .cta-button cta-button--auto        button is auto width
// .cta-button cta-button--auto-sm3    button is auto width starting at sm3 phone-large breakpoint
// .cta-button disabled                opacity .8px
// .cta-button cta-button--contrasted  gray background instead of white
// .cta-button cta-button--solid       all red solid button

.cta-button {
  height: $btn-height-base;
  color: $white;
  background-color: transparent;
  display: block; // take up 100% width of parent container in mobile, later goes to inline-block for phone-large

  &--auto {
    display: inline-block;
  }

  & > a,
  & > button {
    color: $white;
    height: 100%;
  }

  & > a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: $white;
    }
  }

  & > button {
    margin: 0;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 100%; // take up 100% width of parent container
  }

  &__cta {
    position: relative;
    display: block;
    padding-left: 10px;
    padding-right: 10px + $btn-height-base; // to account for red block
    font-size: rem-calc(19px);
    line-height: $btn-height-base;
    font-weight: bold;
    color: $primary-red;
    background-color: $white;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
  }

  &--contrasted .cta-button__cta {
    background-color: $light-gray;
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: $btn-height-base;
    background: $primary-red svg-url('<svg width="12" height="20" viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg"><path d="M11.716 9.36L1.301.194A.766.766 0 0 0 .184.3a.864.864 0 0 0 .1 1.174L9.973 10 .284 18.527a.864.864 0 0 0-.1 1.174.78.78 0 0 0 .609.299c.18 0 .36-.065.508-.194l10.415-9.166A.851.851 0 0 0 12 10a.85.85 0 0 0-.284-.64" fill="#FFF" fill-rule="evenodd"/></svg>') 50% 50% no-repeat;
    cursor: pointer;
  }

  &:hover &__cta {
    background-color: $light-gray-2;
  }

  &--contrasted.disabled {
    opacity: 0.8;

    &:hover .cta-button__cta {
      background-color: $light-gray; // undo the hover for regular state
    }
  }

  &--solid .cta-button__cta,
  &--solid:hover .cta-button__cta {
    color: $white;
    background-color: $primary-red;
    padding-right: 10px + $btn-height-base/2 - 6px; // center between left edge and arrow. 6px is half width of arrow
  }

  @include responsive('>=', 'phone-small') {
    height: $btn-height-phone-small;

    &__cta {
      padding-left: 20px;
      padding-right: 20px + $btn-height-phone-small; // to account for red block
      font-size: rem-calc(24px);
      line-height: $btn-height-phone-small;
    }

    &__action {
      width: $btn-height-phone-small;
    }

    &--solid .cta-button__cta,
    &--solid:hover .cta-button__cta {
      padding-right: 20px + $btn-height-phone-small/2 - 6px; // center between left edge and arrow. 6px is half width of arrow
    }
  }

  @include responsive('>=', 'phone-large') {
    &.cta-button--auto-sm3 {
      display: inline-block; // don't take up 100% width of parent container
    }
  }

  @include responsive('>=', 'tablet') {
    height: $btn-height-tablet;

    &__cta {
      line-height: $btn-height-tablet;
      padding-left: 23px;
      padding-right: 23px + $btn-height-tablet; // to account for red block
    }

    &__action {
      width: $btn-height-tablet;
    }

    &--solid .cta-button__cta,
    &--solid:hover .cta-button__cta {
      padding-right: 23px + $btn-height-tablet/2 - 6px; // center between left edge and arrow. 6px is half width of arrow
    }
  }
}
