// @import '~ui-experience-system.settings';
@import '../../settings';


// xD-TODO: make var, so it can be kept in sync with hero.scss
$dot-callout-square-lineheight: 1.25em;
$dot-callout-square-size: calc(#{$dot-callout-square-lineheight} + 2px);      // 2px for the border 1px around text xD-TODO: make var, match with hero.scss
$dot-callout-square-offset: calc(#{$dot-callout-square-lineheight} + 11px);   // 2px for border + 9px for lettering white left margin

html {
  box-sizing: border-box;
  font-size: $base-font-size;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background: $body-background;
  color: $base-font-color;
}

img {
  max-width: 100%;
}

h1,
.h1 {
  font-size: rem-calc(40px);
}

h2,
.h2 {
  font-size: rem-calc(28px);
}

sup[data-footnote] {
  margin-left: 3px;
  font-size: rem-calc(11px);
  vertical-align: super;
  top: -0.2em;
  left: -0.2em;
  color: inherit;

  & > a {
    color: inherit;
    text-decoration: none;
  }
}

p {
  margin: 0 0 1em 0;
  padding: 0;
}

// add this to any header to get the blue square
// .dot-callout-header:                                                     Proportional square, vertically centered
// .dot-callout-header dot-callout-header--top:                             Proportional square, top aligned
// .dot-callout-header dot-callout-header--hero dot-callout-header--top:    Fixed square goes from mobile 46px square to desktop 75px square, top aligned
.dot-callout-header {
  // TODO: see if we can remove important?
  padding-left: 1.4em;
  position: relative;
  line-height: 1.2;
  z-index: 0;                         // always over the blue dot  // xD-TODO: put this as a variable

  &:before {
    position: absolute;
    top: 0.15em;                        // centered blue square if proportional
    left: 0;
    content: '';
    display: block;
    width: 0.9em;
    height: 0.9em;
    background-color: $light-blue;
    z-index: -1;
  }

  &--top:before {
    //TODO: For IE, the hack should only be -1px (add later with .ie &--top:before)
    //// -1px Hack to align to top + -1px for the border of white around text
    top: -2px;
  }

  &--hero {
    padding-left: $dot-callout-square-offset;
    line-height: $dot-callout-square-lineheight;              // Chrome is 1.27, but IE11 needs to be a tad less

    &:before {
      width: $dot-callout-square-size;
      height: $dot-callout-square-size;
    }
  }
}


.icon-list-check {
  list-style: none;
  margin: 40px 0 0 0;
  padding: 0 0 0 40px;

  & > li {
    position: relative;
    padding: 0.6em 0 0.6em 0.7em;
  }

  & > li:before {
    position: absolute;
    content: '';
    top: calc(0.6em + 5px);   // 0.6em is top LI padding
    left: -1em;
    display: block;
    width: 0.85em;
    height: 0.85em;
    // if we can figure out how to export images from ui-experience-system to any app/page
    // then this won't need to be encoded
    background-color: $white;
    // xD-TODO: use svg-defs instead of inlined image
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyMCAxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMi4yOTIgNy4yMjZsNC44MTggNC41MTcgMTAuNjY3LTEwIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZT0iI0VBNDIyNCIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
    background-size: 90% 90%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}


.prepend-dollar-sign {
  position: relative;

  &:before {
    font-size: 0.66em; // relative to the font it's prepending
    position: relative;
    top: -0.4em;
    right: 0;
    content: '$';
    display: inline;
    color: inherit;
  }
}
