.header_container_mobile {
    display: block;
}
.header_container_desktop {
    display: none;
}
@media screen and (min-width: 768px) {
    .header_container_mobile {
        display: none;
    }
    .header_container_desktop {
        display: block;
    }
}