////
/// @type Color
/// @group colors
////

// reds

/// @type Color
$primary-red: #e84225;
/// @type Color
$light-primary-red: #ea4224;
/// @type Color
$light-red: #ff7c64;
/// @type Color
$pink: #fcb8ab;
/// @type Color
$light-pink: #ffdcd6;
/// @type Color
$error-25: #FFFBFA;
/// @type Color
$error-300: #FDA29B;
/// @type Color
$error-700: #B42318;

// blues

/// @type Color
$dark-blue: #113b5e;
/// @type Color
$dark-blue-2: #1c5291;
/// @type Color
$medium-blue: #086da4;
/// @type Color
$medium-blue-2: #61bbd9;
/// @type Color
$medium-blue-3: #068acd;
/// @type Color
$light-blue: #82cde5;
/// @type Color
$light-blue-2: #64b7d3;
/// @type Color
$light-slate-blue: #99b1bf;
/// @type Color
$light-slate-blue-2: #8aabb6;
/// @type Color
$slate-blue: #658a96;
/// @type Color
$slate-blue-2: #4a6271;
/// @type Color
$light-gray-blue: #cbd8de;

// greens
/// @type Color
$green: #92bc60;
/// @type Color
$green-2: #93bc60;
/// @type Color
$success-25: #F6FEF9;
/// @type Color
$success-300: #6CE9A6;
/// @type Color
$success-700: #027A48;
/// @type Color
$primary-700: #006099;

// yellows
/// @type Color
$medium-yellow: #fbffb1;
/// @type Color
$medium-yellow-2: #fffcdc;
/// @type Color
$medium-yellow-3: #fbf9e1;
/// @type Color
$light-yellow: #feffec;
/// @type Color
$light-yellow-2: #f8f7e9;

// grays
/// @type Color
$white: #fefefe;
/// @type Color
$white-marble: #f7f7f7;
/// @type Color
$gray-marble: #f5f5f5;
/// @type Color
$lightest-gray: #f1f1f1;
/// @type Color
$lightest-gray-2: #edf3f3;
/// @type Color
$lightest-gray-3: #e7eaeb;
/// @type Color
$lightest-gray-4: #e4eaea;
/// @type Color
$light-gray: #e6eaee;
/// @type Color
$light-gray-2: #e2e5e5;
/// @type Color
$light-gray-3: #d0cec0;
/// @type Color
$light-gray-4: #cad8dd;
/// @type Color
$light-gray-5: #cbd8de;
/// @type Color
$light-gray-6: #acbac7;
/// @type Color
$medium-gray: #a0abb3;
/// @type Color
$dark-gray: #8a8a8a;
/// @type Color
$black: #0a0a0a;

// assigning colors

/// @type Color
$body-background: $white;
/// @type Color
$gray-background: $gray-marble;
/// @type Color
$link-color: $medium-blue-3;
/// @type Color
$error-fg: $light-primary-red;
/// @type Color
$error-bg: $light-pink;
/// @type Color
$error-placehoder-fg: $pink;
/// @type Color
$placeholder_fg_color: $light-gray-3;
/// @type Color
$checkMark-light-green: $green;
/// @type Color
$prequalbackground-blue: $dark-blue-2;

// loan grade colors
/// @type Color
$grade-A: #1592d4;
/// @type Color
$grade-B: #82dbf0;
/// @type Color
$grade-C: #00af97;
/// @type Color
$grade-D: #9ece7b;
/// @type Color
$grade-E: #e1e676;
/// @type Color
$grade-F: #fcb813;
/// @type Color
$grade-G: #f28729;
