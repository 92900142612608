// xD-TODO: refactor all buttons to a common base,
// and sane variants for generalform buttons

.form-button {
  display: block;
  width: 100%;
  max-width: 290px;
  height: 50px;
  margin: 20px auto 0 auto;
  padding: 15px 20px; // vertical padding is to center text if button is an <a> tag
  border: 1px solid $dark-blue-2;
  font-size: rem-calc(14px);
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  text-decoration: none;

  &--submit {
    margin-top: 30px;
    color: $white;
    background-color: $dark-blue-2;

    &:hover {
      background-color: darken($dark-blue-2, 5%);
    }

    &:active {
      background-color: darken($dark-blue-2, 10%);
    }
  }

  &--secondary {
    margin-top: 15px;
    color: $dark-blue-2;
    background-color: $white;

    &:hover {
      background-color: darken($white, 5%);
    }

    &:active {
      background-color: darken($white, 10%);
    }
  }

  &--inverse {
    margin-top: 15px;
    border: 2px solid $white;
    color: $white;
    background-color: $dark-blue;

    &:hover {
      background-color: transparentize(darken($white, 5%), 0.9);
    }

    &:active {
      background-color: transparentize(darken($white, 10%), 0.8);
    }
  }
}

@include responsive('>=', 'phone') {
  .form-button {
    max-width: 400px;
  }
}
