@import '../../ui-experience-system/settings/styles.scss';

.form-spinner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-highest;

  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.50;
    background-color: $white;
  }

  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 148px;
    height: 148px;
    background-image: url('~shared_modules/xd-spinner/src/images/spinner.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
