// @import '~ui-experience-system.settings';
@import '../../settings';

@font-face {
  // TBD whether AWS S3 prod (static.lendingclub.com) or served from boreas...boreas for now
  font-family: 'Haas Grotesk Regular';
  src: url('https://static.lendingclub.com/www/src/hosted/fonts/neue-haas-grotesk/subsets/neue-haas-grotesk-text-regular.woff2') format('woff2'), url('https://static.lendingclub.com/www/src/hosted/fonts/neue-haas-grotesk/subsets/neue-haas-grotesk-text-regular.woff') format('woff');
}

@font-face {
  font-family: 'Haas Grotesk Bold';
  src: url('https://static.lendingclub.com/www/src/hosted/fonts/neue-haas-grotesk/subsets/neue-haas-grotesk-text-bold.woff2') format('woff2'), url('https://static.lendingclub.com/www/src/hosted/fonts/neue-haas-grotesk/subsets/neue-haas-grotesk-text-bold.woff') format('woff');
}

body {
  font-family: $base-font-family;
  line-height: $base-line-height;
  letter-spacing: $base-letter-spacing;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// be careful, this specificity is higher than most module-specific CSS. just change the font-family
.fonts-loaded {
  body {
    font-family: $webfont-font-family;
  }

  .bold,
  strong,
  b {
    font-family: $webfont-font-family-bold;
    font-weight: normal;      // Chrome doesn't change weight, but some browsers will make it even bolder
  }
}
