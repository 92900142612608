@import '~shared_modules/ui-experience-system/settings/styles.scss';
@import '~shared_modules/common-css/common-css.scss';
@import '~@ui-propulsion/toolkit/tools';
@import '~@ui-propulsion/core/typography';
@import '~shared_modules/diamond-style-look/diamond-style-look.scss';

$pds-color-denim-500: #113b5e;
$true-white: #ffffff;

.auth-checkbox {
  margin-right: 5px;
}

.masked-email {
  padding-bottom: 30px;
}

.lock-image {
  display: block;
  width: 52;
  height: 71;
  margin: 0 auto 20px auto;
}

.hide-realm-notice .realm-notice {
  display: none;
}

.ssp-disclaimer-message {
  color: $pds-color-denim-500;
  @include font("micro");
  @include font-type("text-regular");
  padding: 8px;
  padding-left: 0;
}

.logged-out-notice {
  background-color: #6f8ca0;
  color: #ffffff;
  height: rem(45px);
  display: flex;
  flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-items: center;
  align-content: stretch;

  .icon {
    margin-right: 0.5rem;
    margin-top: .3rem;
  }
}

.factor-selection {
  font-size: 16px;
  color: $pds-color-denim-500;

  label {
    font-size: 16px;
    margin-left: 0.5rem;
    font-weight: 400;
    color: $pds-color-denim-500;
  }
  span, [data-masked-email], [data-masked-mobile], [data-masked-phone], [data-auth-app] {
    font-size: 16px;
    font-weight: 700;
    color: $pds-color-denim-500;
  }
}

[data-title] {
  margin-bottom: 0.5em;
}

.auth-title {
  font-weight: 600;
  font-size: 0.8em;
}

.auth-description {
  font-size: 0.75em;
}

.authenticate-text {
  color: #4a6988;
}

.min-margin {
  margin: 0.2em 0 0.2em;
}
.enter-code-input {
  margin: 24px 0 0 0;
}

.resend-links {
  font-size: 0.8rem;
}

[data-channel-view] {
  ul {
    list-style: none; /* Remove default bullets */
    padding-left: 1.5em;
  }

  ul li::before {
    content: "\25AA"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: #df3416;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    font-size: 1.5em;
    margin-left: -1em;
  }
}

.button-link {
  border: 0;
  background: transparent;
  color: #068acd;
}

.auth-container {
  display: flex;
  flex-direction: column;
  font-family: Mulish;
  justify-content: center;
  .auth-banner-desktop {
    display: none;
    justify-content: center;
    align-items: flex-end;
    gap: 3.5rem;
    padding: 7.75rem 2.5rem;
    flex-shrink: 0;
    background: linear-gradient(270deg, #0178b3 0%, #1a487c 100%);

    .auth-banner__info { 
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 20.5rem;
      gap: 1rem;

      .auth-banner__title {
        @include text-style(1.875rem, 700, 2.375rem);

        sup {
          vertical-align: middle;
          @include text-style(1rem, 900, normal);
        }
      }

      .auth-banner__subtitle {
        @include text-style(1.125rem, 500, 1.75rem);
      }

      .auth-banner__description {
        @include text-style(0.75rem, 400, 1.123rem);
      }
    }

    .auth-banner__img-image {
      width: 240px;
      height: 494px;
    }
  }
}

@include breakpoint("tablet-md-and-up") {
  .auth-container {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1200px;

    .auth-banner-desktop {
      display: flex;

      .auth-banner__info {
        @include banner-layout(center, center);
      }

      .auth-banner__img {
        display: none;
      }
    }
  }
}

@include breakpoint("tablet-lg-and-up") {
  .auth-container {
    flex-direction: row;
    margin: 0 auto;
    max-width: 1200px;

    .auth-banner-desktop {
      display: flex;

      .auth-banner__info {
        @include banner-layout(flex-start, left);
      }

      .auth-banner__img {
        display: block;
      }
    }
  }
}

.form-button-resend {
  background: $true-white;
  margin-bottom: 32px;
  border: none;

  a {
    font-size: 16px;
    color: $sub-text-color;
  }
}

.choose-diff-met {
  margin-top: 6px;

  a {
    color: $primary-700
  }
}

.auth-sub-header {
  font-family: 'Mulish';
  color: $title-color;
  text-transform: none;
  font-size: 24px;
  font-weight: 700;
}

.auth-sub-header-text {
  font-size: 16px;
  margin: 12px 0 40px;
  color: $title-color;
  font-weight: 400;
}

@include breakpoint('tablet-md-and-up') {
  .auth-sub-header {
      font-size: 30px;
  }
}