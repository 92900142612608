@import '~shared_modules/ui-experience-system/settings/styles.scss';

.notification-box {
  position: fixed;
  z-index: $zindex-hidden;
  top: 16px;
  width: 100%;
  opacity: 0;
  transition: opacity $med_transition_time, height $med_transition_time;
  overflow: hidden;
  width: calc(100% - 16px);
  max-width: 400px;
  padding: 12px 12px 12px 16px;
  display: flex;
  gap: 12px;
  border-radius: 8px;
  animation: slideIn 2s forwards; 

  &.is-success,
  &.is-error,
  &.is-notice {
    opacity: 1;
    z-index: $zindex-highest;

    a {
      color: inherit; // make same color as state text
      text-decoration: underline; // in case parent is not underlined, make underlined so same color text stands out
    }
  }

  &.is-success,
  &.is-notice {
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid $success-300;
    background: $success-25;
  }

  &.is-error {
    border: 1px solid $error-300;
    background: $error-25;
  }

  &__icon {
    width: 20%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: contain;

    &.is-success,
    &.is-notice {
      background: url('~@shared_modules/xd-notification-box/src/images/success-icon.png') no-repeat center;
    }

    &.is-error {
      background: url('~@shared_modules/xd-notification-box/src/images/error-icon.png') no-repeat center;
    }
  }

  &__message {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: rem-calc(14px);

    &.is-success,
    &.is-notice {
      color: $success-700;
    }

    &.is-error {
      color: $error-700;
    }
  }
}

@keyframes slideIn {
  from {
    top: -100px;
  }
  to {
    top: 16px;
  }
}

@include responsive('<=', '400px') {
  [data-notification-container] {
    .notification-box {
      left: 0;
      margin: 0 8px;
      transform: unset;
    }
  }
}