$form-valid: #92bc60;
$form-error-fg: #ea4224;
$form-error-bg: #fff5f4;
$form-help-bg: #ecf7fb;

$btn-height-base: 46px;
$btn-height-phone-small: 55px;
$btn-height-tablet: 60px;
$secondary-btn-height: 50px;

@mixin enabledFormControlLabelState() {
  height: 15px;
  opacity: 1;
  display: inline-block;
}

@mixin disabledFormControlLabelState() {
  height: 0;
  opacity: 0;
  display: none;
}

@mixin placeholder() {
  color: $light-gray-6;
  font-weight: 400;
}
