@import '~@shared_modules/propulsion/core/styles.scss';
@import '~@shared_modules/propulsion/toolkit/tools';
@import "~@ui-propulsion/core/_typography";

.GlobalSecondaryNavBand {
  @include font-type('text-bold');
  display: block;
  width: 100%;
  z-index: 95;
  color: $pds-color-denim-500;
  background-color: $lightest-gray-2;

  a {
    text-decoration: none;
  }

  &__wrapper {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:72px;
  }

  &__logo {
    display: flex;
    opacity: 0;
    pointer-events: none;
    margin-top: 2px;
    &--docked {
      cursor: initial;
      opacity: 1;
      pointer-events: all;
      margin-top: 15px;
    }
  }

  &__tab-list {
    padding: 0;
    margin: 0;
    list-style: none;
    height: 72px;
  }

  &__tab-list-item {
    display: inline-block;
  }

  &__tab-list-item-content {
    @include font-type('text-bold');
    display: inline-flex;
    padding: 27px 18px 27px 18px;
    color: $pds-color-denim-500 !important;
    font-size: 15px;
    line-height: 18px;
    height: 72px;
    align-items: center;

    &:hover {
      color: $pds-color-denim-500;
      box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
      background-color: $white;
      cursor: pointer;
      text-decoration: none;
    }

    &--active {
      @include font-type('text-bold');
      color: $pds-color-denim-500 !important;
      box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
      background-color: $white;
      cursor: pointer;

      &:hover {
        color: $pds-color-denim-500;
        box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
        background-color: $white;
        cursor: pointer;
      }
    }
  }

  &__tab-list-item-narrow {
    display: inline-flex;
    padding-left: 10px;
    padding-right: 10px;
    color: $pds-color-denim-500;
    font-size: 0.75rem;
    height: 72px;
    align-items: center;

    &:hover {
      color: $pds-color-denim-500;
      box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
      background-color: $white;
      cursor: pointer;
    }

    &--active {
      color: $pds-color-denim-500;
      box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
      background-color: $white;
      cursor: pointer;

      &:hover {
        color: $pds-color-denim-500;
        box-shadow: inset 0 -6px 0 $pds-color-cardinal-500;
        background-color: $white;
        cursor: pointer;
      }
    }
  }

}
.docked .GlobalSecondaryNavBand{
  border-top: none;
}
.docked .GlobalSecondaryNavBand .GlobalSecondaryNavBand__tab-list{
  height: 56px;
}
.docked .GlobalSecondaryNavBand .GlobalSecondaryNavBand__tab-list-item-content{
  height: 56px;
}
.docked .GlobalSecondaryNavBand .GlobalSecondaryNavBand__container{
  height: 56px;
}
@include breakpoint('tablet-lg-and-up') {
  .GlobalSecondaryNavBand {
    &__tab-list-item-narrow {
      display: inline-flex;
      padding-left: 14px;
      padding-right: 14px;
      font-size: 0.78125rem;
    }
  }
}

@include breakpoint('desktop-sm-and-up') {
  .GlobalSecondaryNavBand {
    &__tab-list-item-narrow {
      display: inline-flex;
      padding-left: 18px;
      padding-right: 18px;
      font-size: 0.875rem;
    }
  }
}
