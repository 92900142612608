@import '~shared_modules/ui-experience-system/settings/styles.scss';

$zindex-fico-radio: $zindex-highest + 1; // larger than $zindex-highest (bug where checkmark doesn't show up)

.fico {
  display: flex;
  flex-wrap: wrap;

  &__choice {
    position: relative; // to allow the __field below to be position:absolute and fill
    padding: 5px;
    flex: 0 0 50%;
    text-align: left;
  }

  &__button {
    height: 100%;
  }

  &__field {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: $zindex-fico-radio;
  }

  &__button:before {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid;
    float: left;
    margin-top: 5px;
  }

  &__choice:hover &__button:before {
    border: 5px solid;
  }

  &__seperator {
    border-top: 1px solid;
    width: 90%;
  }

  &__text {
    font-size: rem-calc(16px);
    font-weight: bold;
    white-space: nowrap;
    position: relative;
    left: 10px;

    &--no-score {
      top: 5px;
    }
  }

  &__number {
    font-size: rem-calc(13px);
    color: $dark-blue;
    text-transform: uppercase;
    position: relative;
    margin-left: 35px;
  }

  &.next-step .fico__button {
    border-bottom-width: 2px;
  }

  @include responsive('>=', 'phone-small') {
    &__text {
      font-size: rem-calc(18px);
    }
  }

  @include responsive('>=', 'phone-large') {
    .fico {
      &__choice {
        padding: 10px;
      }
    }

    .fico__field:checked + .fico__button:after {
      left: 18px;
      top: 18px;
    }
  }

  @include responsive('>=', 'tablet-small') {
    &__choice {
      flex: 0 0 33%;
    }
  }

  // tablet size the fico scores are horizontal
  @include responsive('>=', 'tablet') {
    flex-wrap: nowrap;

    .fico {
      &__choice {
        flex: 0 0 auto; // IE11 handles 0 0 xx% as not having box-sizing:border-box, so padding breaks.
        width: 20%;
      }

      &__text {
        font-size: rem-calc(18px);
      }

      &__text--no-score {
        top: 0;
      }
    }
  }

  @include responsive('>=', 'desktop-small') {
    .fico {
      &__choice {
        flex: 1 0 auto;
      }

      &__text {
        font-size: rem-calc(21px);
      }

      &__number {
        font-size: rem-calc(15px);
      }

      &__text--no-score {
        margin-top: 0;
      }
    }
  }
}

.fico__field.has-error + .fico__button {
  border-color: $error-fg;

  &:before {
    border-color: $error-fg;
  }
}

.fico__field:checked + .fico__button:before {
  border-color: $checkMark-light-green;
  border-width: 13px;
}

.fico__field:checked + .fico__button:after {
  content: '';
  position: absolute;
  top: 12px;
  left: 12px;
  display: block;
  width: 10px; // the short bar of the mark is half as long as the long bar
  height: 18px;
  border: solid white;
  border-width: 0 2px 2px 0; // creates the inverted 'L' shape
  transform: rotate(45deg);
}

.prequal-fico_seperator {
  width: 95%;
  border-top: 1px solid;
  margin-top: 20px;
  margin: 10px auto;
  border-color: $prequalbackground-blue;
  opacity: 0.28;
}
