$base-font-color: $dark-blue;
$base-font-size: 16px;

// Using technique in https://www.filamentgroup.com/lab/font-events.html
// $base-font-family (system fonts) gets applied to body first, then $webfont-font-family when
// web fonts are loaded via the fontfaceobserver API and a class is applied to the document element.
$base-font-family: 'Helvetica Neue', helvetica, arial, sans-serif;
$base-font-family-bold: $base-font-family;

$base-line-height: 1.3;
$base-letter-spacing: normal;

$webfont-font-family: 'Haas Grotesk Regular', 'Helvetica Neue', helvetica, arial, sans-serif;
$webfont-font-family-bold: 'Haas Grotesk Bold', $webfont-font-family;

// FUTURE AGREED UPON FONT SIZES
