// ---------------------------------
// -----  common factories  --------
// ---------------------------------

/// Create an individual rule in a factory of rules. Do not call directly, only use in mixins to generate factories of rules.
///
/// @access private
/// @group private
///
/// @parameter {String} $prefix - _[Optional]_ A prefix to the newly rule's classname, 'B' _(Block)_ in the <abbr title="Block Element Modifier">BEM</abbr> naming style _.block--modifer_.<br> Use `null` if not wanted.
/// @parameter {String} $modifer - If `$prefix` is not `null`, then this will be the 'M' _(Modifier)_ in the <abbr title="Block Element Modifier">BEM</abbr> naming style _.block--modifer_<br>
///   If `$prefix` is `null`, then this is simply the entirety of the class name for the CSS selector (_.modifer {...}_)
/// @parameter {String} $property - The _property_ of the CSS rule.
/// @parameter {String} $value - The _value_ of the CSS rule.
/// @output A fully created CSS rule with contained a property/value pair.
///
/// @example scss - SCSS Input
///   @include factoryRule(null, 'headline', 'color', 'black');
///   @include factoryRule('headline', 'blue', 'color', 'blue');
///
/// @example css - CSS Output
///   .headline {
///       color: black;
///   }
///   .headline--blue {
///       color: blue;
///   }
///
@mixin factoryRule($prefix, $modifer, $property, $value) {
  @if $prefix == null {
    .#{$modifer} {
      #{$property}: $value;
    }
  } @else { /* stylelint-disable-line block-closing-brace-newline-after */
    .#{$prefix}--#{$modifer} {
      #{$property}: $value;
    }
  }
}

// Same as factoryRule but takes a property map:
//   factoryRuleWithPropertyMap('text', 'small', (font-size: 20px; line-height: 1.5) );
//
@mixin factoryRuleWithPropertyMap($prefix, $modifer, $propertyMap) {
  @if $prefix == null {
    .#{$modifer} {
      @each $property, $value in $propertyMap {
        #{$property}: $value;
      }
    }
  } @else { /* stylelint-disable-line block-closing-brace-newline-after */
    .#{$prefix}--#{$modifer} {
      @each $property, $value in $propertyMap {
        #{$property}: $value;
      }
    }
  }
}

// prefix is optional. use `null` if not wanted
@mixin factory($map, $prefix, $property) {
  @each $modifer, $value in $map {
    @include factoryRule($prefix, $modifer, $property, $value);
  }
}

// prefix is optional. use `null` if not wanted.
// by default responsiveFactory's media queries
// will be >= for mobile first design. if given a
// breakpoint not in the $breakpoints map it assumes
// it is a mobile base style
@mixin responsiveFactory($map, $prefix, $property) {
  @each $modifer, $sizes in $map {
    @each $breakpoint, $value in $sizes {
      @if $breakpoint == 'base' {
        // not a breapoint, so don't put in a media query
        @include factoryRule($prefix, $modifer, $property, $value);
      } @else { /* stylelint-disable-line block-closing-brace-newline-after */
        @include responsive('>=', $breakpoint) {
          @include factoryRule($prefix, $modifer, $property, $value);
        }
      }
    }
  }
}

// Same as responsiveFactory, but each breakpoint has a map of attributes.
//
// $font-sizes: (
//   xs: (
//     base: ('font-size': rem-calc(14px), 'line-height': 1.15),
//     tablet: ('font-size': rem-calc(11px), 'line-height': 1.15)
//   ),
//   ...
// );
@mixin responsiveFactoryWithPropertiesMap($map, $prefix) {
  @each $modifer, $sizes in $map {
    @each $breakpoint, $propertyMap in $sizes {
      @if $breakpoint == 'base' {
        // not a breapoint, so don't put in a media query
        @include factoryRuleWithPropertyMap($prefix, $modifer, $propertyMap);
      } @else { /* stylelint-disable-line block-closing-brace-newline-after */
        @include responsive('>=', $breakpoint) {
          @include factoryRuleWithPropertyMap($prefix, $modifer, $propertyMap);
        }
      }
    }
  }
}

// can be used to get a single value that would otherwise be used in
// a factory, if a module wants the same CSS output but without the
// OOCSS approach (e.g., 10px padding without the equiv of .pad-10)
@function getResponsiveFactoryValue($map, $prefix, $size: base) {
  $values: map-get($map, $prefix);
  @return map-get($values, $size);
}


// ---------------------------------
// ----------  mixins  -------------
// ---------------------------------

@function getBreakpointSize($direction, $breakpoint) {
  $bp: map-get($breakpoints, $breakpoint);

  @if $bp == null {
    // allow numeric breakpoints values for unusual cases
    @return $breakpoint;
  }

  @if $direction == '<' {
    @return $bp - 1;
  } @else if $direction == '>' { /* stylelint-disable-line block-closing-brace-newline-after */
    @return $bp + 1;
  } @else { /* stylelint-disable-line block-closing-brace-newline-after */
    @return $bp;
  }
}

@function getBreakpointShorthand($breakpoint) {
  $col: map-get($col-names, $breakpoint);
  @return $col;
}


///
/// A friendlier way to use CSS media queries.
/// @group layout-helper
///
/// @parameter {String} $direction -
///   Direction to which to apply the media query. Options are:
///   * **<** uses max-width compared to `$breakpoint`, exclusive<br>
///   * **<=** uses max-width compared to `$breakpoint`, inclusive<br>
///   * **>** uses min-width compared to `$breakpoint`, exclusive<br>
///   * **>=** uses min-width compared to `$breakpoint`, inclusive<br>
///   * **between** between $breakpoint and `$breakpoint2`, inclusive on both ends
///
/// @parameter {String | Number} $breakpoint - Will try to match String types against known breakpoint names, and if finds a match (e.g., 'tablet') will use that value. Otherwise expects this to be a Number unit value like 766px
///
/// @parameter {String | Number} $breakpoint2 [null] - Optional, only used for **between** `$direction`. Works the same as `$breakpoint`.
///
/// @output A regular CSS Media Query for screen only.
///
/// @example scss - Mobile-First Media Query (SCSS Input)
///    @include responsive('>=', 'tablet') {
///      .foo {
///        color: red;
///      }
///    }
///
/// @example css - Mobile-First Media Query (CSS Output)
///    @media only screen and (min-width: 768px) {
///      .foo {
///        color: red;
///      }
///    }
@mixin responsive($direction, $breakpoint, $breakpoint2: null) {
  $bp: getBreakpointSize($direction, $breakpoint);
  $bp2: getBreakpointSize($direction, $breakpoint2);
  $dir: map-get($directions, $direction);

  @if $dir == null {
    @warn 'missing direction "#{$direction}"';
  } @else { /* stylelint-disable-line block-closing-brace-newline-after */
    @if $dir == 'between' and $bp2 != null {
      @media only screen and (min-width: $bp) and (max-width: $bp2) {
        @content;
      }
    } @else { /* stylelint-disable-line block-closing-brace-newline-after */
      @media only screen and ($dir: $bp) {
        @content;
      }
    }
  }
}

// add a the CSS "content" property to a likely unused
// pseudo element (:before) on the body element. we
// can read this via JS to determine what breakpoint
// is currently active. media queries will override each
// other so correct one is always the one JS will read.
// NOTE: this same concept could apply to print media queries, DPI, etc
@mixin breakpointBodyLabels() {
  // @at-root needed for cases where we @import this scss into a container other
  // than root (like for hybrid site container in layout service for header/footer)
  @at-root body:before {
    content: 'base';
    display: none;
  }

  .debug-rwd:after {
    // class to debug what breakpoint, add <div class="debug-rwd"></div> anywhere on page
    position: fixed;
    content: 'base';
    z-index: $zindex-highest;
    padding: 0.5em 1em;
    background-color: orange;
  }

  @each $name, $size in $breakpoints {
    @include responsive('>=', $name) {
      // @at-root needed for cases where we @import this scss into a container other
      // than root (like for hybrid site container in layout service for header/footer)
      @at-root body:before {
        content: '#{$name}';
      }

      .debug-rwd:after {
        content: '#{$name}';
      }
    }
  }
}

// function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index +
      str-length($search)), $search, $replace);
  }
  @return $string;
}


///
/// Encode SVGs directly to be used as a background image in CSS.
///
/// @group layout-helper
/// @parameter {String} $svg - An entire (optimzed) SVG element.
/// @return {String} An encoded svg in placed inside __url()__.
///
/// @example scss - SCSS Input
///    .foo {
///      background-image: svg-url('<svg width="16" height="12" viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg"><path d="M8.457 10.172l6.147-7.346a.559.559 0 1 0-.857-.717l-5.72 6.833L2.31 2.11a.56.56 0 0 0-.858.717l6.148 7.346a.559.559 0 0 0 .858 0" stroke="#113B5E" stroke-width="2" fill="#113B5E" fill-rule="evenodd"/></svg>');
///    }
///
/// @example css - CSS Output
///    .foo {
///        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='12' viewBox='0 0 16 12' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.457 10.172l6.147-7.346a.559.559 0 1 0-.857-.717l-5.72 6.833L2.31 2.11a.56.56 0 0 0-.858.717l6.148 7.346a.559.559 0 0 0 .858 0' stroke='%23113B5E' stroke-width='2' fill='%23113B5E' fill-rule='evenodd'/%3E%3C/svg%3E"); }
///    }
@function svg-url($svg) {
  // chunk up string in order to avoid
  // "SystemStackError: stack level too deep"
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk,'"','\'');
    $chunk: str-replace($chunk,'<','%3C');
    $chunk: str-replace($chunk,'>','%3E');
    $chunk: str-replace($chunk,'&','%26');
    $chunk: str-replace($chunk,'#','%23');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url('data:image/svg+xml;charset=utf8,#{$encoded}');
}


// return an aspect ration of width-to-height
// or height-to-width depending on order of passed in
// arguments
@function get-aspect-ratio($val1, $val2) {
  @return $val1 / $val2;
}

// given an original width ($wh) and original height ($oh),
// and at least one of either new width ($new) or new height ($nh),
// return the new dimension not passed in while maintaining aspect
// ratio. if neighter new width or height are passed in, returns 0
@mixin resizeProportionally($ow, $oh, $nw: null, $nh: null) {
  @if $nw == null and $nh != null {
    $aspect-ratio: get-aspect-ratio($ow, $oh);
    $nw: round($aspect-ratio * $nw);
  }
  @if $nh == null and $nw != null {
    $aspect-ratio: get-aspect-ratio($oh, $ow);
    $nh: round($aspect-ratio * $nw);
  }
  width: $nw;
  height: $nh;
}

// mixin to set max page size and center content
@mixin pageContentContainer() {
  max-width: $page-max-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: map-get($page-side-padding, 'base');
  padding-right: map-get($page-side-padding, 'base');

  @include responsive('>=', 'phone-small') {
    padding-left: map-get($page-side-padding, 'phone-small');
    padding-right: map-get($page-side-padding, 'phone-small');
  }

  @include responsive('>=', 'tablet') {
    padding-left: map-get($page-side-padding, 'tablet');
    padding-right: map-get($page-side-padding, 'tablet');
  }
}
